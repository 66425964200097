@import '../../theme/variables';
@import '../../theme/mixins';

.container {
  position: relative;
  height: fit-content;
  border: 1px solid $secondary-color-03;
  border-radius: 8px;
  overflow: hidden;
}

.full {
  width: 100%;
}

.mid {
  max-width: 318px;
}

.coverImage {
  position: relative;
  overflow: hidden;
  background-color: $secondary-color-01;

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
  }
}

.info {
  padding: 1rem 1rem 0.5rem;
}

.productTitle {
  @include limitTextLines();
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.priceLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.priceContainer {
  display: flex;
  gap: 0.8rem;
  align-items: end;
  flex-wrap: wrap;

  .discountContainer {
    height: 27px;
    border-radius: 8px;
    padding: 2px 6px;
    background-color: $primary-color-02;
  }
}

.productPrice {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;

  span {
    color: $secondary-color-02;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
}

.originalPrice {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #000;
}

.productSizes {
  display: grid;
  grid-template-columns: fit-content(40%) 8px auto;
  gap: 0.5rem;
  color: $secondary-color-00;
  margin-bottom: 1rem;

  p {
    @include limitTextLines(1);
    font-size: 1rem;

    span {
      text-decoration: underline;
    }
  }
}

.productOwnerInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  .ownerImage {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.productActions {
  border-top: 1px solid $secondary-color-03;
  padding: 13px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productAction {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 600px) {
  .productSizes {
    grid-template-columns: auto;
    margin-bottom: 2px;
    gap: 2px;
    p {
      @include limitTextLines(1);
      font-size: 12px;
      line-height: 18px;

      span {
        text-decoration: underline;
      }
    }

    .verticalDivider {
      display: none;
    }
  }
}

@media (max-width: $mobiles-width) {
  .info {
    padding: 8px;
  }
  .productOwnerInfo {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    margin-bottom: 0;

    .ownerImage {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .productActions {
    border-top: 1px solid $secondary-color-03;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .productAction {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .productTitle {
    @include limitTextLines();
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 2px;
  }
  .priceLine {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }
  .priceContainer {
    display: flex;
    gap: 0.2rem;
    align-items: end;
    flex-wrap: wrap;

    .productPrice {
      font-size: 14px;
      line-height: 21px;
    }

    .discountContainer {
      height: 19px;
      border-radius: 8px;
      padding: 2px 6px;
      background-color: $primary-color-02;

      .originalPrice {
        letter-spacing: 1px;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  .productPrice {
    font-size: 1.2rem;

    span {
      font-size: 0.75rem;
    }
  }
}

@media (max-width: 390px) {
  .priceContainer {
    display: block;
  }
}
